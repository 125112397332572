import './MainPage.css';
import React from 'react';

export const MainPage = () => {
  return (
    <div className='main_container'>
      <div className='content_block'>
        <p className='percent'>Welcome Bonus up to</p>
        <div style={{ alignItems: 'center', display: 'flex', gap: '30px' }}>
          <span className='text'>1500€</span>
          <span className='text_desc'>+150 FS</span>
        </div>
        <a
          className='App-link'
          href='https://example.com'
          target='_blank'
          rel='noopener noreferrer'
        >
          GET BONUSES
        </a>
        <img src='/img/path.svg' className='path' alt='path' />
      </div>

      <div className='image_block'>
        <img src='/img/star.png' className='star' alt='star' />
        <img src='/img/red.png' className='red' alt='red' />
        <img src='/img/green.png' className='green' alt='green' />
        <img src='/img/yellow.png' className='yellow' alt='yellow' />
        <img src='/img/pink.png' className='pink' alt='pink' />
      </div>
      <div className='footer'>
        <span>Copyright © 2024 «FairPari».</span>
        <span>
          Fairpari.com is owned and operated by CENTRALD B.V. a company
          registered and established under the laws of Curaçao, with
          registration number 166369 and registered address at Abraham Mendez
          Chumaceiro 03, Willemstad, Curaçao and its wholly-owned subsidiary
          acting as a payment agent MATRYOTECH LTD registered in Cyprus with the
          registration number HE 457874 and registered address 5, A.G. LEVENTI,
          THE LEVENTIS GALLERY TOWER,Fl.: 13th,Apt.: 1301, NICOSIA, 1097,
          Cyprus. CENTRALD B.V. is licensed and regulated by the Curacao Gaming
          Control Board (GCB) with Licence Number OGL/2024/1143/0865. Remember
          that gambling can be addictive. Play responsibly.
        </span>
      </div>
    </div>
  );
};
